import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { SelectTeacher } from "./pages/student";
import Users from "./pages/settings/users/List";
import Login from "./pages/Login";
import Layout from "./pages/Layout";
import PrivateRoute from "./components/PrivateRoute";
import UserForm from "./pages/settings/users/Form";
import Permissions from "./pages/settings/permissions/List";
import PermissionForm from "./pages/settings/permissions/Form";
import UserGroups from "./pages/settings/userGroups/List";
import UserGroupsForm from "./pages/settings/userGroups/Form";
import Modules from "./pages/settings/modules/List";
import ModulesForm from "./pages/settings/modules/Form";
import Parameters from "./pages/settings/parameters/List";
import ParametersForm from "./pages/settings/parameters/Form";
import Sessions from "./pages/settings/sessions/List";
import Actions from "./pages/settings/actions/List";
import Skills from "./pages/evaluation/skills/List";
import SkillsForm from "./pages/evaluation/skills/Form";
import MeasurementScaleList from "./pages/evaluation/measurementScale/List";
import MeasurementScaleForm from "./pages/evaluation/measurementScale/Form";
import DepartmentsList from "./pages/evaluation/departments/List";
import DepartmentsForm from "./pages/evaluation/departments/Form";
import ProgramsForm from "./pages/evaluation/programs/Form";
import ProgramsList from "./pages/evaluation/programs/List";
import GradesList from "./pages/evaluation/grades/List";
import GradesForm from "./pages/evaluation/grades/Form";
import PeriodList from "./pages/evaluation/periods/List";
import PeriodForm from "./pages/evaluation/periods/Form";
import QuestionList from "./pages/evaluation/questions/List";
import QuestionForm from "./pages/evaluation/questions/Form";
import GradeGroupsList from "./pages/evaluation/gradeGroups/List";
import GradeGroupsForm from "./pages/evaluation/gradeGroups/Form";
import ReportView from "./pages/evaluation/report/View";
import "./App.css";
import PollTeacher from "./pages/student/PollTeacher";
import Theme from "./assets/theme/theme";
import { ThemeProvider } from "@material-ui/core";
import SurveyList from "./pages/evaluation/survey/List";
import SurveyForm from "./pages/evaluation/survey/Form";

const App = ({ user }) => {
  return (
      <ThemeProvider theme={Theme}>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Navigate to="/sign-in" />} />

            <Route path="/sign-in" element={<Login />} />

            <Route
              path="/users/edit/:id"
              element={<PrivateRoute user={user} />}
            >
              <Route
                path="/users/edit/:id"
                element={
                  <Layout>
                    <UserForm />
                  </Layout>
                }
              />
            </Route>

            <Route path="/users/create" element={<PrivateRoute user={user} />}>
              <Route
                path="/users/create"
                element={
                  <Layout>
                    <UserForm />
                  </Layout>
                }
              />
            </Route>

            <Route path="/evaluation" element={<PrivateRoute user={user} />}>
              <Route path="/evaluation" element={<SelectTeacher />} />
            </Route>

            <Route
              path="/selectingTeacher/:id"
              element={<PrivateRoute user={user} />}
            >
              <Route path="/selectingTeacher/:id" element={<PollTeacher />} />
            </Route>

            <Route path="/users" element={<PrivateRoute user={user} />}>
              <Route
                path="/users"
                element={
                  <Layout>
                    <Users />
                  </Layout>
                }
              />
            </Route>

            <Route
              path="/permissions/create"
              element={<PrivateRoute user={user} />}
            >
              <Route
                path="/permissions/create"
                element={
                  <Layout>
                    <PermissionForm />
                  </Layout>
                }
              />
            </Route>
            <Route
              path="/permissions/edit/:id"
              element={<PrivateRoute user={user} />}
            >
              <Route
                path="/permissions/edit/:id"
                element={
                  <Layout>
                    <PermissionForm />
                  </Layout>
                }
              />
            </Route>
            <Route path="/permissions" element={<PrivateRoute user={user} />}>
              <Route
                path="/permissions"
                element={
                  <Layout>
                    <Permissions />
                  </Layout>
                }
              />
            </Route>

            <Route
              path="/userGroups/edit/:id"
              element={<PrivateRoute user={user} />}
            >
              <Route
                path="/userGroups/edit/:id"
                element={
                  <Layout>
                    <UserGroupsForm />
                  </Layout>
                }
              />
            </Route>
            <Route
              path="/userGroups/create"
              element={<PrivateRoute user={user} />}
            >
              <Route
                path="/userGroups/create"
                element={
                  <Layout>
                    <UserGroupsForm />
                  </Layout>
                }
              />
            </Route>
            <Route path="/userGroups" element={<PrivateRoute user={user} />}>
              <Route
                path="/userGroups"
                element={
                  <Layout>
                    <UserGroups />
                  </Layout>
                }
              />
            </Route>

            <Route
              path="/modules/edit/:id"
              element={<PrivateRoute user={user} />}
            >
              <Route
                path="/modules/edit/:id"
                element={
                  <Layout>
                    <ModulesForm />
                  </Layout>
                }
              />
            </Route>
            <Route
              path="/modules/create"
              element={<PrivateRoute user={user} />}
            >
              <Route
                path="/modules/create"
                element={
                  <Layout>
                    <ModulesForm />
                  </Layout>
                }
              />
            </Route>
            <Route path="/modules" element={<PrivateRoute user={user} />}>
              <Route
                path="/modules"
                element={
                  <Layout>
                    <Modules />
                  </Layout>
                }
              />
            </Route>
            <Route
              path="/parameters/edit/:id"
              element={<PrivateRoute user={user} />}
            >
              <Route
                path="/parameters/edit/:id"
                element={
                  <Layout>
                    <ParametersForm />
                  </Layout>
                }
              />
            </Route>
            <Route
              path="/parameters/create"
              element={<PrivateRoute user={user} />}
            >
              <Route
                path="/parameters/create"
                element={
                  <Layout>
                    <ParametersForm />
                  </Layout>
                }
              />
            </Route>

            <Route path="/parameters" element={<PrivateRoute user={user} />}>
              <Route
                path="/parameters"
                element={
                  <Layout>
                    <Parameters />
                  </Layout>
                }
              />
            </Route>
            <Route path="/sessions" element={<PrivateRoute user={user} />}>
              <Route
                path="/sessions"
                element={
                  <Layout>
                    <Sessions />
                  </Layout>
                }
              />
            </Route>

            <Route path="/actions" element={<PrivateRoute user={user} />}>
              <Route
                path="/actions"
                element={
                  <Layout>
                    <Actions />
                  </Layout>
                }
              />
            </Route>

            <Route
              path="/skills/edit/:id"
              element={<PrivateRoute user={user} />}
            >
              <Route
                path="/skills/edit/:id"
                element={
                  <Layout>
                    <SkillsForm />
                  </Layout>
                }
              />
            </Route>
            <Route path="/skills/create" element={<PrivateRoute user={user} />}>
              <Route
                path="/skills/create"
                element={
                  <Layout>
                    <SkillsForm />
                  </Layout>
                }
              />
            </Route>

            <Route path="/skills" element={<PrivateRoute user={user} />}>
              <Route
                path="/skills"
                element={
                  <Layout>
                    <Skills />
                  </Layout>
                }
              />
            </Route>

            <Route
              path="/measurementScales/edit/:id"
              element={<PrivateRoute user={user} />}
            >
              <Route
                path="/measurementScales/edit/:id"
                element={
                  <Layout>
                    <MeasurementScaleForm />
                  </Layout>
                }
              />
            </Route>
            <Route
              path="/measurementScales/create"
              element={<PrivateRoute user={user} />}
            >
              <Route
                path="/measurementScales/create"
                element={
                  <Layout>
                    <MeasurementScaleForm />
                  </Layout>
                }
              />
            </Route>

            <Route
              path="/measurementScales"
              element={<PrivateRoute user={user} />}
            >
              <Route
                path="/measurementScales"
                element={
                  <Layout>
                    <MeasurementScaleList />
                  </Layout>
                }
              />
            </Route>
            <Route
              path="/departments/create"
              element={<PrivateRoute user={user} />}
            >
              <Route
                path="/departments/create"
                element={
                  <Layout>
                    <DepartmentsForm />
                  </Layout>
                }
              />
            </Route>
            <Route
              path="/departments/edit/:id"
              element={<PrivateRoute user={user} />}
            >
              <Route
                path="/departments/edit/:id"
                element={
                  <Layout>
                    <DepartmentsForm />
                  </Layout>
                }
              />
            </Route>
            <Route path="/departments" element={<PrivateRoute user={user} />}>
              <Route
                path="/departments"
                element={
                  <Layout>
                    <DepartmentsList />
                  </Layout>
                }
              />
            </Route>
            <Route
              path="/programs/create"
              element={<PrivateRoute user={user} />}
            >
              <Route
                path="/programs/create"
                element={
                  <Layout>
                    <ProgramsForm />
                  </Layout>
                }
              />
            </Route>
            <Route
              path="/programs/edit/:id"
              element={<PrivateRoute user={user} />}
            >
              <Route
                path="/programs/edit/:id"
                element={
                  <Layout>
                    <ProgramsForm />
                  </Layout>
                }
              />
            </Route>
            <Route path="/programs" element={<PrivateRoute user={user} />}>
              <Route
                path="/programs"
                element={
                  <Layout>
                    <ProgramsList />
                  </Layout>
                }
              />
            </Route>

            <Route path="/grades/create" element={<PrivateRoute user={user} />}>
              <Route
                path="/grades/create"
                element={
                  <Layout>
                    <GradesForm />
                  </Layout>
                }
              />
            </Route>
            <Route
              path="/grades/edit/:id"
              element={<PrivateRoute user={user} />}
            >
              <Route
                path="/grades/edit/:id"
                element={
                  <Layout>
                    <GradesForm />
                  </Layout>
                }
              />
            </Route>
            <Route path="/grades" element={<PrivateRoute user={user} />}>
              <Route
                path="/grades"
                element={
                  <Layout>
                    <GradesList />
                  </Layout>
                }
              />
            </Route>
            <Route
              path="/periods/create"
              element={<PrivateRoute user={user} />}
            >
              <Route
                path="/periods/create"
                element={
                  <Layout>
                    <PeriodForm />
                  </Layout>
                }
              />
            </Route>
            <Route
              path="/periods/edit/:id"
              element={<PrivateRoute user={user} />}
            >
              <Route
                path="/periods/edit/:id"
                element={
                  <Layout>
                    <PeriodForm />
                  </Layout>
                }
              />
            </Route>

            <Route path="/periods" element={<PrivateRoute user={user} />}>
              <Route
                path="/periods"
                element={
                  <Layout>
                    <PeriodList />
                  </Layout>
                }
              />
            </Route>

            <Route
              path="/questions/create"
              element={<PrivateRoute user={user} />}
            >
              <Route
                path="/questions/create"
                element={
                  <Layout>
                    <QuestionForm />
                  </Layout>
                }
              />
            </Route>
            <Route
              path="/questions/edit/:id"
              element={<PrivateRoute user={user} />}
            >
              <Route
                path="/questions/edit/:id"
                element={
                  <Layout>
                    <QuestionForm />
                  </Layout>
                }
              />
            </Route>
            <Route path="/questions" element={<PrivateRoute user={user} />}>
              <Route
                path="/questions"
                element={
                  <Layout>
                    <QuestionList />
                  </Layout>
                }
              />
            </Route>

            <Route
              path="/gradeGroups/create"
              element={<PrivateRoute user={user} />}
            >
              <Route
                path="/gradeGroups/create"
                element={
                  <Layout>
                    <GradeGroupsForm />
                  </Layout>
                }
              />
            </Route>
            <Route
              path="/gradeGroups/edit/:id"
              element={<PrivateRoute user={user} />}
            >
              <Route
                path="/gradeGroups/edit/:id"
                element={
                  <Layout>
                    <GradeGroupsForm />
                  </Layout>
                }
              />
            </Route>
            <Route path="/gradeGroups" element={<PrivateRoute user={user} />}>
              <Route
                path="/gradeGroups"
                element={
                  <Layout>
                    <GradeGroupsList />
                  </Layout>
                }
              />
            </Route>

            <Route
              path="/surveys/create"
              element={<PrivateRoute user={user} />}
            >
              <Route
                path="/surveys/create"
                element={
                  <Layout>
                    <SurveyForm />
                  </Layout>
                }
              />
            </Route>
            <Route
              path="/surveys/edit/:id"
              element={<PrivateRoute user={user} />}
            >
              <Route
                path="/surveys/edit/:id"
                element={
                  <Layout>
                    <SurveyForm />
                  </Layout>
                }
              />
            </Route>
            <Route path="/surveys" element={<PrivateRoute user={user} />}>
              <Route
                path="/surveys"
                element={
                  <Layout>
                    <SurveyList />
                  </Layout>
                }
              />
            </Route>
            <Route path="/report" element={<PrivateRoute user={user} />}>
              <Route
                path="/report"
                element={
                  <Layout>
                    <ReportView />
                  </Layout>
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps, null)(App);
