import React from 'react'
import { Button, Modal } from 'react-bootstrap';

const ModalCustom = (
    {
        title = "",
        children,
        textButtom = "",
        buttonAction = () => false,
        buttonCancelAction = () => {setShow(false)},
        button = false,
        buttonCancel = false,
        fullscreen = false,
        show = false,
        setShow = () => false,
    }
) => {
    return (
        <Modal show={show} fullscreen={fullscreen}>
            <Modal.Header className='fw-bolder fs-3'>
                {title}
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
                {
                    button && (
                        <Button onClick={buttonAction} variant="outline-primary">{textButtom}</Button>
                    )
                }
                {
                    buttonCancel && (
                        <Button onClick={buttonCancelAction} variant="outline-danger">Cancelar</Button>
                    )
                }
            </Modal.Footer>
        </Modal>
    )
}

export default ModalCustom;