import React from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Sort,
  Filter,
  ExcelExport,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { connect } from "react-redux";
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { FindInPageOutlined } from "@material-ui/icons";
import { Header } from "../../../components";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../../api";
import { encrypt } from "../../../utils/crypt";
import Swal from "sweetalert2";
import BackdropComponent from "../../../components/Backdrop";

const ModuleList = (props) => {
  const history = useNavigate();
  const { token, user, permission, groupId, userId } = props;

  const fileName = "módulos";

  const [users, setModules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filtro, setFiltro] = useState([]);

  const editButton = (props) => {
    const history = useNavigate();
    const action = (e) => {
        
      history(`/modules/edit/${encrypt(props.id_modulos)}`);
    };
    return (
      <Button
        onClick={action}
        style={{ backgroundColor: "#9d2d39", color: "white" }}
        disabled={!permission.includes(3)}
      >
        Editar
      </Button>
    );
  };

  const ViewButton = (props) => {
    const [actions, setActions] = useState([]);

    const [openDialog, setOpenDialog] = useState(false);

    const action = () => {
      setOpenDialog(true);
    };

    const closeDialog = () => {
      setOpenDialog(false);
    };

    const getActions = async () => {
      const { data } = await axios.post(
        `/moduleAction/getActionsModules`,
        {
          id_modulos: props.id_modulos,
        },
        {
          headers: { "access-token": token },
        }
      );
      setActions(data.actions);
    };

    useEffect((e) => {
      getActions();
    }, []);

    return (
      <>
        <Tooltip title="Ver acciones">
          <IconButton aria-label="actions" onClick={() => action()}>
            <Typography style={{ color: "#3C3C3C" }}>
              {props.count_acciones}
            </Typography>
            <FindInPageOutlined />
          </IconButton>
        </Tooltip>

        <Dialog
          open={openDialog}
          onClose={closeDialog}
          fullWidth={false}
          scroll={"body"}
          maxWidth={"xs"}
        >
          <DialogContent>
            <List>
              <ListItem>
                <ListItemIcon>
                  <b>N°</b>
                </ListItemIcon>
                <ListItemText>
                  <b>Acciones</b>
                </ListItemText>
              </ListItem>
              <Divider />
              {actions
                ?.sort((a, b) =>
                  a.acciones?.nombre <
                  b.acciones?.nombre
                    ? -1
                    : 1
                )
                .map((item, index) => (
                  <ListItem key={`accion${index}`}>
                    <ListItemIcon>{index + 1}</ListItemIcon>
                    <ListItemText>{item.acciones?.nombre}</ListItemText>
                  </ListItem>
                ))}
            </List>
          </DialogContent>
        </Dialog>
      </>
    );
  };

  const deleteButton = (props) => {
    const modalDelete = (id) => {
      Swal.fire({
        text: "¿Está seguro que desea eliminar este módulo?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#9d2d39",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.value) {
          sendDelete(id);
        }
      });
    };

    const sendDelete = async (id) => {
      setLoading(true);
      const { data } = await axios.delete(`/moduleAction/${id}`, {
        data: { id_usuarios: userId },
        headers: { "access-token": token },
      });
      setFiltro(users.filter((item) => item.id !== id));
      setLoading(false);
      Swal.fire({
        text: "Eliminado exitosamente.",
        icon: "success",
        showConfirmButton: false,
        timer: 3000,
      });
      getModules();
    };

    return (
      <Button
        onClick={() => {
          modalDelete(props.id)
        }}
        disabled={!permission.includes(4)}
        style={{ backgroundColor: "#9d2d39", color: "white" }}
      >
        Eliminar
      </Button>
    );
  };

  const ModulesGrid = [
    {
      headerText: "Módulo",
      textAlign: "Center",
      field: "modulos.nombre",
    },
    {
      headerText: "Acciones",
      textAlign: "Center",
      template: ViewButton,
    },
    {
      template: editButton,
      headerText: "Editar",
      textAlign: "Center",
    },
    {
      template: deleteButton,
      headerText: "Eliminar",
      textAlign: "Center",
    },
  ];

  const addUserGroup = {
    align: "Left",
    click: () => {
      history(`/modules/create`);
    },
    disabled: !permission.includes(2),
    id: "grid_245085904_1_add",
    overflow: "None",
    prefixIcon: "e-add",
    showTextOn: "Both",
    text: "Crear módulos",
    tooltipText: "Add",
    type: "Button",
    visible: true,
    width: "auto",
  };

  useEffect(() => {
    if (permission.includes(1)) {
      getModules();
    } else {
      history("/");
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getModules = async () => {
    const { data } = await axios.post(
      `/moduleAction/getActionsModulesByModules`,
      {},
      {
        headers: { "access-token": token },
      }
    );
    setModules(data?.actions);
    setFiltro(data?.actions);
  };

  const searchOptions = {
    fields: ["nombre"],
    ignoreCase: true,
    ignoreAccent: true,
    operator: "contains",
    key: "",
  };

  const dataToExcel = async (data) => {
    // eslint-disable-next-line array-callback-return
    const arrayExcel = data?.map((item) => {
      return {
        Nombre: item.nombre,
      };
    });
    exportToExcel(arrayExcel);
  };

  const excelExport = {
    align: "Left",
    click: () => {
      dataToExcel(users);
    },
    disabled: !permission.includes(5),
    id: "grid_245085904_0_excelexport",
    overflow: "None",
    prefixIcon: "e-excelexport",
    showTextOn: "Both",
    text: "Exportar",
    tooltipText: "ExcelExport",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const exportToExcel = (jsonData) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    if (jsonData.length > 0) {
      const ws = XLSX.utils.json_to_sheet(jsonData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };

  const toolbarOptions = ["Search", addUserGroup, excelExport];

  const editing = { allowDeleting: true, allowEditing: true };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Configuración" title="Módulos" />
      <GridComponent
        dataSource={users}
        width="auto"
        locale="es-ES"
        allowPaging
        allowSorting
        pageSettings={{ pageCount: 10 }}
        searchSettings={searchOptions}
        editSettings={editing}
        allowTextWrap
        toolbar={toolbarOptions}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {ModulesGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Search, Page, Sort, Filter, Toolbar, ExcelExport]} />
      </GridComponent>
      <BackdropComponent loading={loading} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.token,
    user: state.user,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 3)
      .map((item) => item.modulosAcciones?.id_acciones),
    userId: state.user.id,
    groupId: state.user.id_grupos_usuarios,
  };
};
export default connect(mapStateToProps, null)(ModuleList);
