import { CardActionArea, makeStyles } from "@material-ui/core";
import React from "react";
import Card from "react-bootstrap/Card";

const CardCustom = (props) => {
    const classes = useStyles();
    const {
        title = "Titulo por defecto",
        details = "Descripción por defecto",
        estado = 234,
        onClick = () => {
            return;
        },
    } = props;

    const Status = ({ estado }) => {
        let status = "";
        let style = {};
        switch (estado) {
            case 322:
                status = "En Curso";
                style = { border: "2px solid #9D1004" };
                break;
            case 323:
                status = "Finalizado";
                style = { background: "#C89E32", color: "#FFFF" };
                break;
            default:
                status = "Sin Iniciar";
                style = { background: "#9D1004", color: "#FFFF" };
                break;
        }
        return (
            <Card.Text style={style} className="p-1 rounded-3xl mt-2">
                {status}
            </Card.Text>
        );
    };

    return (
        <CardActionArea className={classes.container} onClick={onClick}>
            <Card style={{ borderRadius: "20px" }}>
                <Card.Body className={classes.cardBody}>
                    <Card.Title>{title}</Card.Title>
                    <Card.Subtitle className="my-2">{details}</Card.Subtitle>
                    <Status estado={props.estado} />
                </Card.Body>
            </Card>
        </CardActionArea>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        borderRadius: "20px",
        margin: "10px",
        [theme.breakpoints.down(700)]: {
            margin: '0 0 8px 0',
        },
    },
    cardBody: {
        maxHeight: "300px",        
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        [theme.breakpoints.down(700)]: {
            maxHeight: "170px",
        },
    },
    text: {
        maxWidth: "80%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
}));

export default CardCustom;
