import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { loginRequest, setPermissions, setToken } from "../actions";
import {
  makeStyles,
  Grid,
  Button,
  CssBaseline,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from "@material-ui/core";
import axios from "../api";
import Swal from "sweetalert2";
import HomeBackground from "../assets/img/Login_BG.png";
import Logo from "../assets/img/logocucv1.png";
import Backdrop from "../components/Backdrop";
import { useEffect } from "react";

import { authFirebase, providerMicrosoft } from "../data/firebase";
import { signInWithPopup, OAuthProvider } from "firebase/auth";

function SignIn(props) {
  const { user, loginRequest, setPermissions, setToken } = props;
  const history = useNavigate();
  const classes = useStyles();
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [authType, setAuthType] = useState("ldap");

  useEffect(() => {
    if (Object.keys(user || {}).length > 0) {
      if (user?.id_grupos_usuarios === 1 || user?.id_grupos_usuarios === 2) {
        history("/report");
      } else {
        history("/evaluation");
        window.location.reload();
      }
    }
  }, []);

  useEffect(() => {
    if (authType == "ldap") {
      setForm({
        ...form,
        usuario: "",
      });
    } else {
      setForm({
        ...form,
        email: "",
        password: "",
      });
    }
  }, [authType]);

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (authType == "ldap") {
      if (form.usuario == undefined || form.usuario == "") {
        Swal.fire({
          icon: "error",
          text: "Ingrese un usuario.",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        providerMicrosoft.setCustomParameters({
          prompt: "login",
          login_hint: `${form.usuario}@cuc.edu.co`,
        });
        ["User.Read", "openid", "profile", "offline_access"].forEach(
          (scope) => {
            providerMicrosoft.addScope(scope);
          }
        );
        signInWithPopup(authFirebase, providerMicrosoft)
          .then((res) => {
            const accessToken = OAuthProvider.credentialFromResult(res).accessToken;
            setLoading(true);
            axios.post(`/user/signin`, { accessToken }).then((res) => {
              if (res.data.user.success) {
                loginRequest(res.data?.user?.user);
                setPermissions(res.data?.user?.permissions);
                setToken(res.data?.user?.token);
                if (
                  res.data?.user?.user?.id_grupos_usuarios === 1 ||
                  res.data?.user?.user?.id_grupos_usuarios === 2
                ) {
                  history("/report");
                } else {
                  history("/evaluation");
                  window.location.reload();
                }
                setLoading(false);
              } else {
                Swal.fire({
                  icon: "error",
                  text: res.data?.user?.message,
                  showConfirmButton: false,
                  timer: 3000,
                });
                setLoading(false);
              }
            });
          })
          .catch((err) => console.log(err));
      }
    } else {
      setLoading(true);
      axios
        .post(`/user/signin`, form)
        .then((res) => {
          if (res.data.user.success) {
            loginRequest(res.data?.user?.user);
            setPermissions(res.data?.user?.permissions);
            setToken(res.data?.user?.token);
            if (
              res.data?.user?.user?.id_grupos_usuarios === 1 ||
              res.data?.user?.user?.id_grupos_usuarios === 2
            ) {
              history("/report");
            } else {
              history("/evaluation");
              window.location.reload();
            }
            setLoading(false);
          } else {
            Swal.fire({
              icon: "error",
              text: res.data?.user?.message,
              showConfirmButton: false,
              timer: 3000,
            });
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            text: "Usuario y/o contraseña incorrecta.",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={12} className={classes.image}>
        <div className={classes.paper} style={{}}>
          <h1>Test</h1>
          <div>
            <img
              alt="logo"
              src={Logo}
              style={{ height: "90px", margin: "10px 0" }}
            />
          </div>
          <p style={{ fontSize: "24px", fontWeight: "700", margin: "10px 0" }}>
            Acceder
          </p>
          <form className={classes.form} onSubmit={handleSubmit}>
            <FormControl
              required
              fullWidth
              variant="outlined"
              className={classes.customOutlinedInput}
            >
              <InputLabel id="authLabel">Tipo de Autenticación</InputLabel>
              <Select
                labelId="authLabel"
                label="Tipo de Autenticación"
                value={authType}
                onChange={(e) => setAuthType(e.target.value)}
                inputProps={{
                  name: "authType",
                  id: "auth-type-input",
                }}
              >
                <MenuItem value={"ldap"}>Institucional</MenuItem>
                <MenuItem value={"local"}>Administrativo</MenuItem>
              </Select>
            </FormControl>
            {authType == "ldap" && (
              <TextField
                required
                fullWidth
                variant="outlined"
                margin="normal"
                label="Usuario"
                name="usuario"
                autoComplete="usuario"
                value={form.usuario}
                type="usuario"
                onChange={handleInput}
                InputProps={{
                  classes: {
                    root: classes.container__input_root,
                  },
                }}
              />
            )}
            {authType == "local" && (
              <>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  label="Correo electrónico"
                  name="email"
                  autoComplete="email"
                  type="email"
                  onChange={handleInput}
                  InputProps={{
                    classes: {
                      root: classes.container__input_root,
                    },
                  }}
                />
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  key="Contraseña"
                  label="Contraseña"
                  name="password"
                  autoComplete="current-password"
                  type="password"
                  onChange={handleInput}
                  InputProps={{
                    classes: {
                      root: classes.container__input_root,
                    },
                  }}
                />
              </>
            )}
            <Button
              color="primary"
              type="submit"
              variant="contained"
              className={classes.submit}
            >
              Iniciar sesión
            </Button>
          </form>
        </div>
      </Grid>
      <Backdrop loading={loading} />
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  anchor: {
    color: "#9d2d39",
    "&:hover": {
      color: "#9d2d39",
    },
  },
  image: {
    backgroundImage: `url(${HomeBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "center",
    padding: "5%",
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
  },
  paper: {
    backgroundColor: "#ffffff",
    borderRadius: "16px",
    width: "35%",
    padding: "40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "90%",
    [theme.breakpoints.down("sm")]: {
      width: "400px",
    },
    [theme.breakpoints.down(500)]: {
      width: "90%",
    },
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    width: "13em",
    fontWeight: "bolder",
    borderRadius: "10px",
    backgroundColor: "#9d2d39",
    "&:hover": {
      backgroundColor: "#9d2d39",
    },
  },
  container__input_root: {
    borderRadius: "10px",
  },
  containerLogo: {
    margin: "-3em 0 3em 0",
  },
  textField: {
    margin: "11em 1em 0 1em",
    color: theme.palette.background.main,
    fontWeight: "bolder",
    fontSize: "42px",
    textShadow: "2px 2px 2px black",
    display: "none",
  },
  notchedOutline: {
    /* borderColor: "#9d2d39 !important", */
  },
  customOutlinedInput: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      borderColor: "#000 !important",
    },
  },
  color: {
    color: "#000 !Important",
  },
}));

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToprops = {
  loginRequest,
  setPermissions,
  setToken,
};

export default connect(mapStateToProps, mapDispatchToprops)(SignIn);
