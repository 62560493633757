// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, OAuthProvider } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCFiBlox6eF1jIqN1i3wB-fgzjPBZjLG7I",
  authDomain: "evaldocente-af349.firebaseapp.com",
  projectId: "evaldocente-af349",
  storageBucket: "evaldocente-af349.appspot.com",
  messagingSenderId: "1013245510070",
  appId: "1:1013245510070:web:32b54da33fd72ebe67c117",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const authFirebase = getAuth(app);
export const providerMicrosoft = new OAuthProvider("microsoft.com");
