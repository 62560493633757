import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#9d2d39",
      light: "#9DFEAA",
      dark: "#201644",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#000000",
      light: "#545454",
      dark: "#000000",
      contrastText: "#ffffff",
    },
    background: {
      main: "#9d2d39",
    },
  },
});

export default theme;