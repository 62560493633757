import React from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Sort,
  Filter,
  ExcelExport,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { connect } from "react-redux";
import { Header } from "../../../components";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import FindInPageOutlinedIcon from "@material-ui/icons/FindInPageOutlined";
import axios from "../../../api";
import { IconButton, Tooltip } from "@material-ui/core";
import Swal from "sweetalert2";
import BackdropComponent from "../../../components/Backdrop";
import moment from "moment";

const Sessions = (props) => {
  const history = useNavigate();
  const { token, user, permission, groupId, userId } = props;

  const fileName = "sesiones";

  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filtro, setFiltro] = useState([]);

  const sesionToken = (props) => {
    const handleDialog = (token) => {
      Swal.fire({
        title: "Token de la sesión",
        text: token,
        showConfirmButton: false,
      });
    };
    return (
      <Tooltip title="Ver token">
        <IconButton
          aria-label="token"
          onClick={() => handleDialog(props.token_sesion)}
        >
          <FindInPageOutlinedIcon />
        </IconButton>
      </Tooltip>
    );
  };

  const SessionsGrid = [
    {
      headerText: "Fecha",
      textAlign: "Center",
      field: "fecha_sesion",
      template: (props) => {
        return moment(props.fecha_sesion).format("DD/MM/YYYY HH:mm:ss");
      },
    },
    {
      headerText: "Sesión Id",
      textAlign: "Center",
      field: "sesion_id",
    },
    {
      headerText: "Token",
      textAlign: "Center",
      template: sesionToken,
    },
    {
      headerText: "Usuario",
      textAlign: "Center",
      template: (props) =>
        `${props?.usuarios?.nombres} ${props?.usuarios?.apellidos}`.trim(),
    },
  ];


  useEffect(() => {
    if (permission.includes(1)) {
      getSessions();
    } else {
      history("/");
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSessions = async () => {
    const { data } = await axios.post(
      `/session/getSessions`,
      {},
      {
        headers: { "access-token": token },
      }
    );
    setSessions(data?.sessions);
    setFiltro(data?.sessions);
  };

  const searchOptions = {
    fields: ["fecha_sesion","sesion_id","props.usuarios.nombres","props.usuarios.apellidos","token_sesion"],
    ignoreCase: true,
    ignoreAccent: true,
    operator: "contains",
    key: "",
  };

  const dataToExcel = async (data) => {
    // eslint-disable-next-line array-callback-return
    const arrayExcel = data?.map((item) => {
      return {
        "Fecha": moment(item.fecha_sesion).format("DD/MM/YYYY HH:mm:ss"),
        "Id de sesión": item.sesion_id,
        "Usuario":`${item?.usuarios?.nombres} ${item?.usuarios?.apellidos}`.trim(),
        "Token": item.token_sesion
      };
    });
    exportToExcel(arrayExcel);
  };

  const excelExport = {
    align: "Left",
    click: () => {
      dataToExcel(sessions);
    },
    disabled: !permission.includes(5),
    id: "grid_245085904_0_excelexport",
    overflow: "None",
    prefixIcon: "e-excelexport",
    showTextOn: "Both",
    text: "Exportar",
    tooltipText: "ExcelExport",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const exportToExcel = (jsonData) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    if (jsonData.length > 0) {
      const ws = XLSX.utils.json_to_sheet(jsonData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };

  const toolbarOptions = ["Search", excelExport];

  const editing = { allowDeleting: true, allowEditing: true };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Configuración" title="Sesiones" />
      <GridComponent
        dataSource={sessions}
        width="auto"
        locale="es-ES"
        allowPaging
        allowSorting
        pageSettings={{ pageCount: 10 }}
        searchSettings={searchOptions}
        editSettings={editing}
        allowTextWrap
        toolbar={toolbarOptions}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {SessionsGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Search, Page, Sort, Filter, Toolbar, ExcelExport]} />
      </GridComponent>
      <BackdropComponent loading={loading} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.token,
    user: state.user,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 7)
      .map((item) => item.modulosAcciones?.id_acciones),
    userId: state.user.id,
    groupId: state.user.id_grupos_usuarios,
  };
};
export default connect(mapStateToProps, null)(Sessions);
