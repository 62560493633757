import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { setBreadcrumps } from "../../../actions";
import {
  makeStyles,
  TextField,
  Button,
  Grid,
  Paper,
  Divider,
} from "@material-ui/core";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Search,
  Page,
  Sort,
  Filter,
  Toolbar,
  ExcelExport,
} from "@syncfusion/ej2-react-grids";
import Swal from "sweetalert2";
import Header from "../../../components/Header";
import Backdrop from "../../../components/Backdrop";
import axios from "../../../api";
import { decrypt } from "../../../utils/crypt";
import { useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { MdDeleteForever } from "react-icons/md";

function FormMeasurementScale(props) {
  const { userId, permission, token } = props;
  const params = useParams();
  const classes = useStyles();
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    nombre: ""
  });
  const [detallesEscala, setDetallesEscala] = useState([]);
  const ref = useRef({});

  const getGroup = async () => {
    const id = decrypt(params.id);
    const { data } = await axios.get(`/measurementScale/${id}`, {
      headers: { "access-token": token },
    });
    setDetallesEscala(data.measurementScale?.detalleEscalaMedicion || []);
    setForm(data.measurementScale);
  };

  const deleteButton = (props) => {
    let data = props.column.parent.currentViewData;

    const removeRatings = (id) => {
      Swal.fire({
        text: "¿Está seguro que desea eliminar esta valoracion?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#9d2d39",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          sendDelete(id)
        }
      });
    };

    const sendDelete = async (id) => {
      setDetallesEscala(data.filter((e, index) => index != id))
      Swal.fire({
        text: "Eliminado exitosamente.",
        icon: "success",
        showConfirmButton: false,
        timer: 3000,
      });
    };

    return (
      <Button
        onClick={() => {
          removeRatings(props.index);
        }}
        style={{
          color: "#9d2d39",
          borderRadius: "50%",
          height: "25px",
          width: "5px",
        }}
      >
        <MdDeleteForever size={25} />
      </Button>
    );
  };

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleCancel = () => {
    history("/measurementScales");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!params.id) {
      axios
        .post(
          `/measurementScale/`,
          { ...form, detallesEscala, userId },
          {
            headers: { "access-token": token },
          }
        )
        .then((res) => {
          setLoading(false);
          history("/measurementScales");
          Swal.fire({
            icon: "success",
            text: "Creado exitosamente.",
            showConfirmButton: false,
            timer: 3000,
          });
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            text: "No se ha podido crear.",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    } else {
      const id = decrypt(params.id);
      axios
        .put(
          `/measurementScale/${id}`,
          { ...form, detallesEscala, userId },
          {
            headers: { "access-token": token },
          }
        )
        .then((res) => {
          setLoading(false);
          history("/measurementScales");
          Swal.fire({
            icon: "success",
            text: "Editado exitosamente.",
            showConfirmButton: false,
            timer: 3000,
          });
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            text: "No se ha podido editar.",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    }
  };

  const DetailScaleMeasurementGrid = [
    {
      headerText: "Rotulo",
      textAlign: "Center",
      width: "100",
      field: "rotulo",
    },
    {
      headerText: "Valor",
      textAlign: "Center",
      width: "50",
      field: "valor",
    },
    {
      template: deleteButton,
      headerText: "",
      width: "30",
      textAlign: "Center",
    },
  ];

  const dataToExcel = async () => {
    const arrayExcel = form.detalleEscalaMedicion?.map((item) => {
      return {
        Rotulos: item.rotulo,
        Valor: item.valor,
      };
    });
    exportToExcel(arrayExcel);
  };

  const exportToExcel = (jsonData) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    if (jsonData.length > 0) {
      const ws = XLSX.utils.json_to_sheet(jsonData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, "Valores" + fileExtension);
    }
  };

  const excelExport = {
    align: "Left",
    click: dataToExcel,
    disabled: !permission.includes(5),
    id: "grid_245085904_0_excelexport",
    overflow: "None",
    prefixIcon: "e-excelexport",
    showTextOn: "Both",
    text: "Exportar",
    tooltipText: "ExcelExport",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const addRatings = () => {
    Swal.fire({
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#9d2d39",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      html: '<spam>Ingrese los datos del rotulo<spam><input id="swal-input1" placeholder="Rotulo" class="swal2-input"> <input type="number" id="swal-input2" placeholder="valor" class="swal2-input">',
      preConfirm: () => {
        return {
          rotulo: document.getElementById("swal-input1").value,
          valor: parseInt(document.getElementById("swal-input2").value),
        };
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        setDetallesEscala([...detallesEscala, result.value]);
      }
    });
  };

  useEffect(() => {
    if (permission.includes(2) || permission.includes(3)) {
      if (params.id) {
        getGroup();
      }
    } else {
      history("/");
      window.location.reload();
    }
  }, []);

  return (
    <>
      <Paper elevation={3}>
        <Divider />
        <div className={classes.paper}>
          <div className={classes.container}>
            <Header
              category="Parametrización evaluación docente"
              title={`${params.id ? "Editar" : "Crear"} escala de medición`}
            />
            <form className={classes.root} onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <TextField
                    required
                    fullWidth
                    label="Nombre"
                    name="nombre"
                    value={form.nombre}
                    variant="outlined"
                    onChange={handleInput}
                    InputProps={{
                      classes: {
                        root: classes.container__input_root,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    onClick={addRatings}
                  >
                    Añadir Valoración
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <GridComponent
                    ref={(g) => (ref.current = g)}
                    dataSource={detallesEscala}
                    width="auto"
                    locale="es-ES"
                    allowPaging
                    allowSorting
                    allowTextWrap
                    toolbar={["Search", excelExport]}
                    pageSettings={{ pageCount: 5 }}
                    searchSettings={{
                      fields: ["valor", "rotulo"],
                      ignoreCase: true,
                      ignoreAccent: true,
                      operator: "contains",
                      key: "",
                    }}
                    editSettings={{ allowDeleting: true, allowEditing: false }}
                  >
                    <ColumnsDirective>
                      {DetailScaleMeasurementGrid.map((item, index) => (
                        <ColumnDirective key={index} {...item} />
                      ))}
                    </ColumnsDirective>
                    <Inject
                      services={[
                        Search,
                        Page,
                        Sort,
                        Filter,
                        Toolbar,
                        ExcelExport,
                      ]}
                    />
                  </GridComponent>
                </Grid>
              </Grid>
              <div className={classes.containerButton}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  type="submit"
                >
                  Guardar
                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={handleCancel}
                >
                  Cancelar
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Paper>
      <Backdrop loading={loading} />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "1em",
  },
  paper: {
    margin: theme.spacing(2, 2),
    paddingBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  container: {
    width: "80%",
  },
  containerButton: {
    marginTop: "1em",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      display: "block",
      marginTop: "1em",
      marginBottom: "1em",
    },
  },
  button: {
    margin: "0.5em",
    padding: ".5em 3em",
    borderRadius: "10px",
    backgroundColor: "#9d2d39",
    "&:hover": {
      backgroundColor: "#9d2d39",
    },
  },
  container__input_root: {
    borderRadius: "10px",
  },
}));

const mapStateToProps = (state) => {
  return {
    userId: state.user.id,
    token: state.token,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 9)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

const mapDispatchToProps = {
  setBreadcrumps,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormMeasurementScale);