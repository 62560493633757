import { Box, Button, Checkbox, Step, StepButton, Stepper, TextareaAutosize, useMediaQuery } from '@material-ui/core';
import { useNavigate, useParams } from "react-router-dom";
import { Header, ModalCustom } from '../../components';
import { useState, useEffect } from 'react';
import { decrypt } from "../../utils/crypt";
import { ListGroup, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import Swal from "sweetalert2";
import axios from '../../api';
import "../../App.css";
import { IoIosArrowBack } from 'react-icons/io';

const PollTeacher = (props) => {
    const params = useParams();
    const [competencias, setCompetencias] = useState([]);
    const [showModal, setShowModal] = useState(true);
    const [activeStep, setActiveStep] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [comments, setComments] = useState("");
    const [orden, setOrden] = useState(0);
    const [blockButton, setBlockButton] = useState(false);
    const [vp_tipo_encuesta, setVp_tipo_encuesta] = useState(0);
    const history = useNavigate();
    const { token } = props;
    const dataId = decrypt(params.id);
    const isMobile = useMediaQuery('(max-width: 700px)');

    // const getCompetencias = async () => {
    //     axios.post("/evaluations/getCompetencies", {
    //         where: {
    //             id: [25, 26, 27, 31],
    //             estado: 1,
    //         }
    //     }, {
    //         headers: { "access-token": token },
    //     }).then((result) => {
    //         const list = [...result.data?.listCompetencies]
    //         list.push({
    //             id: 32,
    //             nombre: "Comentarios",
    //             orden: 27,
    //             estado: 1
    //         })
    //         setCompetencias(list);
    //     }).catch((err) => {
    //         console.error(err);
    //     });
    // };

    const getQuestions = async () => {
        axios.post("/evaluations/getQuestions", {
            data: {
                id: dataId,
            }
        }, {
            headers: { "access-token": token },
        }).then(async ({ data }) => {
            const { dataResult, response } = data.respuesta;
            setVp_tipo_encuesta(dataResult[0].vp_tipo_encuesta);
            if (dataResult.length > 0) {
                const questionsList = dataResult[0]?.detalleEncuestas.map(item => item.preguntas);
                let competitionListTemp = [];
                const list = questionsList.map(item => {
                    const calificacion = response.find(item2 => item2.id_preguntas === item.id)
                    let value = []
                    if (calificacion) {
                        value = item?.escalaMedicion?.detalleEscalaMedicion.map(item3 => ({
                            rotulo: item3.rotulo,
                            value: item3.valor,
                            status: item3.valor === calificacion.calificacion
                        })
                        );
                    } else {
                        value = item?.escalaMedicion?.detalleEscalaMedicion.map(item3 => ({
                            rotulo: item3.rotulo,
                            value: item3.valor,
                            status: false,
                        })
                        );
                    }
                    competitionListTemp.push(item.competencia);
                    return {
                        ...item,
                        value
                    }
                });

                var hash = {};
                let competitionList = competitionListTemp.filter((current) => {
                    var exists = !hash[current.id];
                    hash[current.id] = true;
                    return exists;
                });

                competitionList = competitionList.sort(
                    (p1, p2) => (p1.orden > p2.orden) ? 1 : (p1.orden < p2.orden) ? -1 : 0)
                competitionList.push({
                    id: 32,
                    nombre: "Comentarios",
                    orden: 27,
                    estado: 1
                });

                setOrden(response[0]?.orden || 0);
                await setCompetencias(competitionList);
                await setQuestions(list);
            } else {
                Swal.fire({
                    icon: "error",
                    text: "Error al Consultar preguntas, contacte con el administrador.",
                    showConfirmButton: false,
                    timer: 3000,
                });
            }
        }).catch((err) => {
            console.error(err);
        });
    };

    const salveEvaluation = async () => {
        const list = questions.filter(item =>
            item.value.filter(item2 => item2.status).length === 1
        );

        const listResult = list.map(item => ({
            ...item,
            value: item.value.filter(item2 => item2.status)[0].value
        }));

        if (listResult.length !== questions.length) {
            Swal.fire({
                icon: "error",
                text: "debe completar las respuestas de la encuesta.",
                showConfirmButton: false,
                timer: 3000,
            });
            return;
        }

        if (comments.trim().length < 4) {
            Swal.fire({
                icon: "error",
                text: "El comentario debe tener al menos mas de 4 caracteres.",
                showConfirmButton: false,
                timer: 3000,
            });
            return;
        }

        const data = {
            id_evaluacion: dataId,
            fecha_final: new Date(),
            juicio_evaluativo: comments.trim(),
            vp_tipo_encuesta
        };

        axios.post('evaluations/endEvaluation', {
            data
        }, {
            headers: { "access-token": token },
        }).then(({ data }) => {
            if (data.respuesta) {
                Swal.fire({
                    icon: "success",
                    text: data.message,
                    showConfirmButton: false,
                    timer: 3000,
                });
                history(`/evaluation`);
            } else {
                Swal.fire({
                    icon: "error",
                    text: "Error al guardar la información",
                    showConfirmButton: false,
                    timer: 3000,
                });
            }
        }).catch(err => {
            console.error(err);
        });
    };

    useEffect(async () => {
        Promise.all(
            [
                // getCompetencias(),
                getQuestions(),
            ]
        );
    }, []);

    const multiSelect = (e) => {
        const list = questions.map(item => {
            if ((parseInt(e.target.value) !== 0) && item.id_competencias === competencias[activeStep].id) {
                item.value.map(value => {
                    if (value.value === parseInt(e.target.value)) {
                        value.status = true;
                    } else {
                        value.status = false;
                    }
                });
            } else if (item.id_competencias === competencias[activeStep].id) {
                item.value.map(value => {
                    value.status = false;
                });
            }
            return item;
        });
        setQuestions(list);
        e.target.value = "0";
    };

    useEffect(() => {

        window.scrollTo({ top: 0, behavior: 'instant' });
    }, [
        activeStep
    ])

    const salveQuestion = async () => {
        setBlockButton(true)
        let list = questions.filter(item => {
            return item.id_competencias === competencias[activeStep].id
        })
        list = list.map(item => {
            const value = item.value.find(item2 => item2.status == true);
            return (
                {
                    id: item.id,
                    calificacion: value.value
                })

        })
        list = list.filter(item => item);



        try {
            await axios.post('/evaluations/salveEvaluationDetail', {
                data: {
                    orden,
                    id_evaluacion: dataId,
                    preguntas: list
                }
            }, {
                headers: { "access-token": token },
            })
            
            setOrden(orden + list.length);
            setActiveStep(activeStep + 1);
        } catch (e) {
            console.error(e);
            Swal.fire({
                icon: "error",
                text: "Error al guardar la respuestas.",
                showConfirmButton: false,
                timer: 3000,
            });
        }

        setBlockButton(false)
    };

    const handleNext = async () => {
        const questionsActiveStep = questions.filter(item => item.id_competencias === competencias[activeStep].id).length
        const answers = questions.filter(item =>
            item.id_competencias === competencias[activeStep].id
            &&
            item.value.filter(item2 => item2.status).length === 1
        ).length;
        if (answers !== questionsActiveStep) {
            Swal.fire({
                icon: "error",
                text: "Debe diligenciar todas las preguntas antes de continuar",
                showConfirmButton: false,
                timer: 3000,
            });
        } else {

            await salveQuestion();

        }
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
        window.scrollTo({ top: 0, behavior: 'instant' });
    };

    const activeCheck = (e, id) => {
        const newList = questions.map(item => {
            if (item.id === id) {
                const value = item.value.map(item2 => {
                    if (item2.value == e.target.value) {
                        item2.status = e.target.checked;
                    } else {
                        item2.status = false;
                    }
                    return item2;
                });
                item.value = value;
            }
            return item;
        })
        setQuestions(newList);
    };

    return (
        <div style={{ backgroundColor: '#ECEAE4', height: '100%', minHeight: '100vh' }} className="pt-4">
            <ModalCustom
                title='Instructivo'
                show={showModal}
                textButtom='Aceptar'
                buttonAction={() => { setShowModal(false) }}
                fullscreen
                button
            >
                <p className='fs-6 text-capitalize text-justify'>
                    Apreciado estudiante para la Universidad de la Costa, la evaluación es un proceso de
                    permanente mirada y en la que todos los miembros de la comunidad institucional estamos
                    inmersos con alto sentido de compromiso y responsabilidad; de ahí, que el presente
                    instrumento busca identificar desde su experiencia de aprendizaje del actual período
                    académico, las fortalezas y oportunidades de mejora en las prácticas de aula de nuestros
                    profesores. Para ello, usted encontrará una serie de afirmaciones de las cuales, en
                    coherencia con su principio de objetividad y fundamento ético, DEBERÁ valorar en una
                    escala de Uno (1) a Cinco (5) así:
                </p>
                <div className='d-flex justify-content-center '>
                    <div className={isMobile ? "col-0" : "col-4"}>
                        <table className='table table-hover border'>
                            <thead className='fw-bolder text-center'>
                                <tr >
                                    <th scope="col">Valoración</th>
                                    <th scope="col">Nivel de Desempeño</th>
                                </tr>
                            </thead>
                            <tbody className="text-center">

                                {
                                    questions.length > 0 && (
                                        questions[0].value.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.value}</td>
                                                <td>{item.rotulo}</td>
                                            </tr>
                                        ))
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <p className='fs-6 text-capitalize text-justify'>
                    Tómese el tiempo necesario para dar cuenta de cada aspecto,
                    pues su información será de gran apoyo a los procesos de calidad que orientan la Misión,
                    Visión y Objetivos de la Universidad.
                </p>
            </ModalCustom>
            {!showModal && (<div className='container'>
                <Header category="Evaluación de Docente" title='Evaluación del Docente' />
                <button
                    className="btn mb-4 button_icons  p-1"
                    title='Regresar'
                    style={{ borderRadius: "50%" }}
                    onClick={() => {
                        salveQuestion();
                        history(`/evaluation`);
                    }}
                >
                    <IoIosArrowBack size={20} />
                </button>

                <Stepper nonLinear activeStep={activeStep} orientation={isMobile ? 'vertical' : 'horizontal'}>
                    {
                        competencias.map((item) => (
                            <Step key={item.id}>
                                <StepButton >
                                    {item.nombre}
                                </StepButton>
                            </Step>
                        ))
                    }
                </Stepper>
                {
                    // (competencias.length > 0 ? competencias[activeStep].id : 0) !== 32 && (
                    //     <div id="multiSelect" className='col-2 mt-3' style={{ display: "none" }} disabled >
                    //         <Form.Group className="d-flex text-center align-items-center">
                    //             <Form.Label >Autocompletar: </Form.Label>
                    //             <Form.Select className='mx-2' id='selectMulti' onChange={multiSelect} >
                    //                 <option defaultValue value={0}></option>
                    //                 {
                    //                     questions.length > 0 && (
                    //                         questions[0].value.map((item, index) => (
                    //                             <option key={index} value={item.value}>
                    //                                 {item.rotulo}
                    //                             </option>
                    //                         ))
                    //                     )
                    //                 }
                    //             </Form.Select>
                    //         </Form.Group>
                    //     </div>
                    // )
                }

                {
                    activeStep < competencias.length - 1 && (
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, pb: 1 }} >
                            {
                                activeStep > 0 && (
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        style={{ backgroundColor: "#9c2e38", color: "#fff" }}
                                    >
                                        Atrás
                                    </Button>
                                )
                            }
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button
                                style={{ backgroundColor: "#9c2e38", color: "#fff" }}
                                disabled={activeStep === competencias.length - 1 || blockButton}
                                id="btnUp"
                                onClick={handleNext} >
                                Siguiente
                            </Button>
                        </Box>
                    )
                }

                <>
                    <div className={activeStep < 4 ? 'd-flex justify-content-center my-2' : 'd-flex justify-content-center my-4'} style={{ backgroundColor: '#fff' }}>
                        <div className="col-12">
                            {
                                (competencias.length > 0 ? competencias[activeStep].id : 0) !== 32 ? (

                                    !isMobile ? (<table className='table table-hover border'>
                                        <thead className='fw-bolder text-center'>
                                            <tr>
                                                <th></th>
                                                {
                                                    questions.length > 0 && (
                                                        questions[0].value.map((item, index) => (
                                                            <th key={index}>{item.rotulo}</th>
                                                        ))
                                                    )
                                                }
                                            </tr>
                                        </thead>
                                        <tbody className="text-center">
                                            {
                                                questions.filter(item => item.id_competencias === competencias[activeStep].id).map((item, index) => (<tr key={item.id}>
                                                    <td className="text-justify col-7" >
                                                        <span className='fw-bolder text-center'>
                                                            {`${index + 1}. `}
                                                        </span>
                                                        {`${item.pregunta}`}
                                                    </td>
                                                    {
                                                        item.value.map(item2 => (
                                                            <td key={item2.value}>
                                                                <Checkbox
                                                                    value={item2.value}
                                                                    checked={item2.status}
                                                                    onChange={(e) => { activeCheck(e, item.id) }}
                                                                    style={{ color: '#9c2e38' }} />
                                                            </td>
                                                        ))
                                                    }
                                                </tr>)
                                                )
                                            }
                                        </tbody>
                                    </table>) : (
                                        questions.filter(item => item.id_competencias === competencias[activeStep].id).map((item, index) => (
                                            <div key={item.id}>
                                                <p className="text-justify p-2" >
                                                    <span className='fw-bolder text-center'>
                                                        {`${index + 1}. `}
                                                    </span>
                                                    {`${item.pregunta}`}
                                                </p>
                                                <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', margin: 5 }}>
                                                    <div>
                                                        {
                                                            questions.length > 0 && (
                                                                questions[0].value.map((item, index) => (
                                                                    <div style={{ height: 42, display: 'flex', alignItems: 'center' }}>
                                                                        <p key={index}>{item.rotulo}</p>
                                                                    </div>
                                                                ))
                                                            )
                                                        }
                                                    </div>
                                                    <div>
                                                        {
                                                            item.value.map(item2 => (
                                                                <div key={item2.value}>
                                                                    <Checkbox
                                                                        value={item2.value}
                                                                        checked={item2.status}
                                                                        onChange={(e) => { activeCheck(e, item.id) }}
                                                                        style={{ color: '#9c2e38' }} />
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>

                                            </div>)
                                        )
                                    )
                                ) :
                                    (
                                        <TextareaAutosize
                                            placeholder="Comentario"
                                            style={{ width: '100%', minHeight: "120px", padding: "1rem" }}
                                            value={comments}
                                            onChange={(e) => { setComments(e.target.value) }} />
                                    )
                            }
                        </div>
                    </div>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 1, pb: 1 }} >
                        {
                            activeStep > 0 && (
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    style={{ backgroundColor: "#9c2e38", color: "#fff" }}
                                >
                                    Atrás
                                </Button>
                            )
                        }

                        <Box sx={{ flex: '1 1 auto' }} />
                        {
                            activeStep === competencias.length - 1  ? (
                                <Button
                                    style={{ backgroundColor: "#9c2e38", color: "#fff" }}
                                    // disabled={activeStep === competencias.length - 1}
                                    onClick={salveEvaluation}>
                                    Finalizar
                                </Button>
                            ) :
                                (
                                    <Button
                                        style={{ backgroundColor: "#9c2e38", color: "#fff" }}
                                        disabled={activeStep === competencias.length - 1 || blockButton}
                                        onClick={handleNext} >
                                        Siguiente
                                    </Button>
                                )
                        }
                    </Box>

                </>
            </div>)}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userId: state.user.id,
        token: state.token,
        permission: (state.permission || [])
            .filter((data) => data.modulosAcciones?.id_modulos === 10)
            .map((item) => item.modulosAcciones?.id_acciones),
    };
};

export default connect(mapStateToProps, null)(PollTeacher);