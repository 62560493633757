import React from "react";
import {
  makeStyles,
  Button,
  Select,
  FormControl,
  InputLabel,
  useTheme,
  Typography,
  ListItem,
  ListItemText,
  Checkbox,
  Grid,
  Paper,
  Divider,
  MenuItem,
} from "@material-ui/core";
import fileDownload from "js-file-download";
import { connect } from "react-redux";
import { Header } from "../../../components";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../../api";
import BackdropComponent from "../../../components/Backdrop";
import PieChart from "../../../components/Charts/PieChart";
import SimpleBarChart from "../../../components/Charts/SimpleBarChart";
import Swal from "sweetalert2";

const Departments = (props) => {
  const history = useNavigate();
  const classes = useStyles();
  const { token, user, permission, groupId, userId } = props;
  const [loading, setLoading] = useState(false);
  const [period, setPeriod] = useState([]);
  const [report, setReport] = useState([]);
  const [piechart, setPieChart] = useState([]);
  const [simpleBarChart, setSimpleBarChart] = useState([]);
  const [form, setForm] = useState({
    periodo: "",
  });

  useEffect(() => {
    if (permission.includes(1)) {
      getReport();
      getPeriod();
    } else {
      history("/");
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getReport = async () => {
    const { data } = await axios.post(
      `/evaluationDetail/getreport`,
      {},
      {
        headers: { "access-token": token },
      }
    );
    setReport(data.report);
    setSimpleBarChart([
      {
        name: "Estadistica",
        Finalizado: data.report.finalizado,
        "No Iniciado": data.report.pendiente,
        "En curso": data.report.enCurso,
      },
    ]);
    setPieChart([
      { name: "Finalizado", value: data.report.finalizado },
      { name: "No Iniciado", value: data.report.pendiente },
      { name: "En Curso", value: data.report.enCurso },
    ]);
  };
  const getPeriod = async () => {
    const { data } = await axios.post(
      `/period/getPeriods`,
      {},
      {
        headers: { "access-token": token },
      }
    );
    setPeriod(data.periods);
  };
  const activateReport = async () => {
    setLoading(true);
    const { data } = await axios.post(
      `/evaluationDetail/activateReport`,
      {
        periodId: form.periodo,
      },
      {
        headers: { "access-token": token },
      }
    );
    if (!data.createdEvaluationDetailId.success) {
      Swal.fire({
        icon: "error",
        text: "No se ha podido activar el reporte.",
        showConfirmButton: false,
        timer: 3000,
      });
      setLoading(false);
    } else {
      Swal.fire({
        icon: "success",
        text: "Se ha generado el nuevo reporte",
        showConfirmButton: false,
        timer: 3000,
      });
      setLoading(false);
    }
  };

  const ReportOfPeriod = async () => {
    setLoading(true);
    const { data } = await axios.post(
      `/evaluationDetail/getevaluationDetails`,
      {
        periodId: form.periodo,
      },
      {
        headers: { "access-token": token },
      }
    );
    if (data.evaluationDetails.success) {
      Swal.fire({
        icon: "success",
        text: "Se ha generado el nuevo reporte",
        showConfirmButton: false,
        timer: 3000,
      });
      setLoading(false);
    } else {
      Swal.fire({
        icon: "error",
        text: data.evaluationDetails.error,
        showConfirmButton: false,
        timer: 3000,
      });
      setLoading(false);
    }
  };

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .get(`${form.periodo}.zip`, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, `${form.periodo}.zip`);
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          text: "No se ha generado un reporte para este periodo.",
          showConfirmButton: false,
          timer: 3000,
        });
      });
    setLoading(false);
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Reportes" title="Reportes" />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <div style={{ width: 450 }}>
            <PieChart data={piechart} />
          </div>
          <div style={{ width: 450 }}>
            <SimpleBarChart data={simpleBarChart} />
          </div>
        </div>
        <div>
          <p>Periodo {report.periodo}</p>
          <br />
          <p>Total estudiantes: {report.totalEstudiantes}</p>
          <p>Evaluaciones finalizadas: {report.finalizado}</p>
          <p>Evaluaciones en Curso: {report.enCurso}</p>
          <p>Evaluaciones No Iniciado: {report.pendiente}</p>
        </div>
      </div>
      <div>
        <br />
        <form className={classes.root} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl required fullWidth variant="outlined">
                <InputLabel id="gruposLabel">Periodo</InputLabel>
                <Select
                  labelId="period"
                  label="Periodo"
                  value={form.periodo}
                  onChange={handleInput}
                  name="periodo"
                  className={classes.container__input_root}
                >
                  <MenuItem value="" disabled>
                    <em>Seleccione una opción</em>
                  </MenuItem>
                  {period.map((data) => (
                    <MenuItem key={`period-${data.id}`} value={data.nombre}>
                      {data.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <div className={classes.containerButton}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Button
                  color="primary"
                  disabled={!form.periodo}
                  variant="contained"
                  className={classes.button}
                  onClick={activateReport}
                >
                  Activar reporte
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  color="primary"
                  disabled={!form.periodo}
                  variant="contained"
                  className={classes.button}
                  onClick={ReportOfPeriod}
                >
                  Generar reporte
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={!form.periodo}
                  className={classes.button}
                  type="submit"
                >
                  Descargar Reporte
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>
      </div>
      <BackdropComponent loading={loading} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "1em",
  },
  paper: {
    margin: theme.spacing(2, 2),
    paddingBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  container: {
    width: "80%",
  },
  containerButton: {
    marginTop: "1em",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      display: "block",
      marginTop: "1em",
      marginBottom: "1em",
    },
  },
  button: {
    margin: "0.5em",
    padding: ".5em 3em",
    borderRadius: "10px",
    backgroundColor: "#9d2d39",
    "&:hover": {
      backgroundColor: "#9d2d39",
    },
  },
  iconMargin: {
    margin: theme.spacing(2),
  },
  check: {
    padding: "5px",
    color: "#9d2d39",
  },
  container__input_root: {
    borderRadius: "10px",
  },
}));

const mapStateToProps = (state) => {
  return {
    token: state.token,
    user: state.user,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 10)
      .map((item) => item.modulosAcciones?.id_acciones),
    userId: state.user.id,
    groupId: state.user.id_grupos_usuarios,
  };
};
export default connect(mapStateToProps, null)(Departments);
