/* eslint-disable quotes */
import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../assets/img/logocucv1.png";
import { MdOutlineCancel } from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import {
  FiUser,
  FiCheck,
  FiUsers,
  FiMenu,
  FiList,
  FiLogOut,
  FiActivity,
  FiMove,
  FiTarget,
  FiAward,
  FiBook,
  FiClipboard,
  FiCalendar,
  FiInfo,
  FiBarChart
} from "react-icons/fi";
import { useStateContext } from "../contexts/ContextProvider";
import { connect } from "react-redux";
import { logoutRequest } from "../actions/index";
const Sidebar = (props) => {
  const { currentColor, activeMenu, setActiveMenu, screenSize } =
    useStateContext();
  const { logoutRequest, permission } = props;

  useEffect(() => { }, []);

  // const logout = () => {
  //   logoutRequest();
  // };

  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const activeLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2";
  const normalLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2";

  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <Link
              to="/"
              onClick={handleCloseSideBar}
              className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
            >
              <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <img src={Logo} style={{ maxWidth: "56%" }} />
              </div>
            </Link>
            <TooltipComponent content="Menu" position="BottomCenter">
              <button
                type="button"
                onClick={() => setActiveMenu(!activeMenu)}
                style={{ color: currentColor }}
                className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
              >
                <MdOutlineCancel />
              </button>
            </TooltipComponent>
          </div>
          {(permission.includes(8) ||
            permission.includes(9) ||
            permission.includes(10) ||
            permission.includes(11) ||
            permission.includes(12) ||
            permission.includes(13) ||
            permission.includes(14) ||
            permission.includes(15) ||
            permission.includes(16) ||
            permission.includes(17)) && (
              <>
                <div className="mt-4 ">
                  <p className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase">
                    Parametrización evaluación docente
                  </p>
                  {permission.includes(16) && (
                    <NavLink
                      to="/report"
                      onClick={handleCloseSideBar}
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? currentColor : "",
                      })}
                      className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                      }
                    >
                      <FiBarChart />
                      <span className="capitalize ">Reportes</span>
                    </NavLink>
                  )}
                  {permission.includes(8) && (
                    <NavLink
                      to="/skills"
                      onClick={handleCloseSideBar}
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? currentColor : "",
                      })}
                      className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                      }
                    >
                      <FiTarget />
                      <span className="capitalize ">Competencias</span>
                    </NavLink>
                  )}
                  {permission.includes(9) && (
                    <NavLink
                      to="/measurementScales"
                      onClick={handleCloseSideBar}
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? currentColor : "",
                      })}
                      className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                      }
                    >
                      <FiCheck />
                      <span className="capitalize ">Escalas de medición</span>
                    </NavLink>
                  )}
                  {permission.includes(10) && (
                    <NavLink
                      to="/departments"
                      onClick={handleCloseSideBar}
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? currentColor : "",
                      })}
                      className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                      }
                    >
                      <FiAward />
                      <span className="capitalize ">Departamentos</span>
                    </NavLink>
                  )}
                  {permission.includes(11) && (
                    <NavLink
                      to="/programs"
                      onClick={handleCloseSideBar}
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? currentColor : "",
                      })}
                      className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                      }
                    >
                      <FiBook />
                      <span className="capitalize ">Programas</span>
                    </NavLink>
                  )}
                  {permission.includes(12) && (
                    <NavLink
                      to="/grades"
                      onClick={handleCloseSideBar}
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? currentColor : "",
                      })}
                      className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                      }
                    >
                      <FiClipboard />
                      <span className="capitalize ">Materias</span>
                    </NavLink>
                  )}
                  {permission.includes(13) && (
                    <NavLink
                      to="/periods"
                      onClick={handleCloseSideBar}
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? currentColor : "",
                      })}
                      className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                      }
                    >
                      <FiCalendar />
                      <span className="capitalize ">Periodos</span>
                    </NavLink>
                  )}
                  {permission.includes(14) && (
                    <NavLink
                      to="/questions"
                      onClick={handleCloseSideBar}
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? currentColor : "",
                      })}
                      className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                      }
                    >
                      <FiInfo />
                      <span className="capitalize ">Preguntas</span>
                    </NavLink>
                  )}
                  {permission.includes(15) && (
                    <NavLink
                      to="/gradeGroups"
                      onClick={handleCloseSideBar}
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? currentColor : "",
                      })}
                      className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                      }
                    >
                      <FiUsers />
                      <span className="capitalize ">Grupos de materias</span>
                    </NavLink>
                  )}
                  {permission.includes(17) && (
                    <NavLink
                      to="/surveys"
                      onClick={handleCloseSideBar}
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? currentColor : "",
                      })}
                      className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                      }
                    >
                      <FiBarChart />
                      <span className="capitalize ">Encuestas</span>
                    </NavLink>
                  )}
                </div>
              </>
            )}
          {(permission.includes(1) ||
            permission.includes(2) ||
            permission.includes(3) ||
            permission.includes(4) ||
            permission.includes(5) ||
            permission.includes(6) ||
            permission.includes(7)) && (
              <>
                <div className="mt-10 ">
                  <p className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase">
                    Configuración
                  </p>
                  {permission.includes(2) && (
                    <NavLink
                      to="/users"
                      onClick={handleCloseSideBar}
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? currentColor : "",
                      })}
                      className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                      }
                    >
                      <FiUser />
                      <span className="capitalize ">Usuarios</span>
                    </NavLink>
                  )}
                  {permission.includes(5) && (
                    <NavLink
                      to="/permissions"
                      onClick={handleCloseSideBar}
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? currentColor : "",
                      })}
                      className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                      }
                    >
                      <FiCheck />
                      <span className="capitalize ">Permisos</span>
                    </NavLink>
                  )}
                  {permission.includes(1) && (
                    <NavLink
                      to="/userGroups"
                      onClick={handleCloseSideBar}
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? currentColor : "",
                      })}
                      className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                      }
                    >
                      <FiUsers />
                      <span className="capitalize ">Grupos de usuario</span>
                    </NavLink>
                  )}
                  {permission.includes(3) && (
                    <NavLink
                      to="/modules"
                      onClick={handleCloseSideBar}
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? currentColor : "",
                      })}
                      className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                      }
                    >
                      <FiMenu />
                      <span className="capitalize ">Módulos</span>
                    </NavLink>
                  )}
                  {permission.includes(6) && (
                    <NavLink
                      to="/parameters"
                      onClick={handleCloseSideBar}
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? currentColor : "",
                      })}
                      className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                      }
                    >
                      <FiList />
                      <span className="capitalize ">Parámetros</span>
                    </NavLink>
                  )}
                  {permission.includes(6) && (
                    <NavLink
                      to="/sessions"
                      onClick={handleCloseSideBar}
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? currentColor : "",
                      })}
                      className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                      }
                    >
                      <FiActivity />
                      <span className="capitalize ">Sesiones</span>
                    </NavLink>
                  )}
                  {permission.includes(4) && (
                    <NavLink
                      to="/actions"
                      onClick={handleCloseSideBar}
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? currentColor : "",
                      })}
                      className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                      }
                    >
                      <FiMove />
                      <span className="capitalize ">Acciones</span>
                    </NavLink>
                  )}
                </div>
              </>
            )}
          <div className="mt-10 ">
            <p className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase">
              Sesión
            </p>
            <NavLink
              to="/"
              onClick={logoutRequest}
              style={({ isActive }) => ({
                backgroundColor: isActive ? currentColor : "",
              })}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <FiLogOut />
              <span className="capitalize ">Cerrar sesión</span>
            </NavLink>
          </div>
        </>
      )}
    </div>
  );
};

const mapDispatchToprops = {
  logoutRequest,
};

const mapStateToProps = (state) => {
  return {
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_acciones === 1)
      .map((item) => item.modulosAcciones?.id_modulos),
  };
};

export default connect(mapStateToProps, mapDispatchToprops)(Sidebar);
