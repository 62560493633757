import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { setBreadcrumps } from "../../../actions";
import {
  makeStyles,
  TextField,
  Button,
  Grid,
  Typography,
  Paper,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import Swal from "sweetalert2";
import Header from "../../../components/Header";
import Backdrop from "../../../components/Backdrop";
import axios from "../../../api";
import { decrypt } from "../../../utils/crypt";
import { useParams } from "react-router-dom";
import { DateTimePicker } from "@material-ui/pickers";
import moment from "moment";

function FormPeriods(props) {
  const { setBreadcrumps, userId, permission, token } = props;
  const params = useParams();
  const classes = useStyles();
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    nombre: "",
    fecha_inicio: null,
    fecha_fin: null,
  });

  useEffect(() => {
    if (permission.includes(2) || permission.includes(3)) {
      if (params.id) {
        getGroup();
      }
    } else {
      history("/");
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGroup = async () => {
    const id = decrypt(params.id);
    const { data } = await axios.get(`/period/${id}`, {
      headers: { "access-token": token },
    });
    setForm(data.period);
  };

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleCancel = () => {
    history("/periods");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (moment(form?.fecha_inicio).isAfter(moment(form?.fecha_fin))) {
      Swal.fire({
        icon: "error",
        text: "La fecha de fin no puede ser menor que la fecha de inicio, por favor revise su entrada.",
        timer: 1500,
        showConfirmButton: false,
      });
      setLoading(false)
    } else {
      if (!params.id) {
        let _form = { ...form };
        _form["fecha_inicio"] = moment(_form["fecha_inicio"]).format(
          "yyyy-MM-DD HH:mm:ss"
        );
        _form["fecha_fin"] = moment(_form["fecha_fin"]).format("yyyy-MM-DD HH:mm:ss");
        axios
          .post(
            `/period/`,
            { ..._form, userId },
            {
              headers: { "access-token": token },
            }
          )
          .then((res) => {
            setLoading(false);
            history("/periods");
            Swal.fire({
              icon: "success",
              text: "Creado exitosamente.",
              showConfirmButton: false,
              timer: 3000,
            });
          })
          .catch((error) => {
            setLoading(false);
            Swal.fire({
              icon: "error",
              text: "No se ha podido crear.",
              showConfirmButton: false,
              timer: 3000,
            });
          });
      } else {
        const id = decrypt(params.id);
        let _form = { ...form };
        _form["fecha_inicio"] = moment(_form["fecha_inicio"]).format(
          "yyyy-MM-DD HH:mm:ss"
        );
        _form["fecha_fin"] = moment(_form["fecha_fin"]).format("yyyy-MM-DD HH:mm:ss");
        axios
          .put(
            `/period/${id}`,
            { ..._form, userId },
            {
              headers: { "access-token": token },
            }
          )
          .then((res) => {
            setLoading(false);
            history("/periods");
            Swal.fire({
              icon: "success",
              text: "Editado exitosamente.",
              showConfirmButton: false,
              timer: 3000,
            });
          })
          .catch((error) => {
            setLoading(false);
            Swal.fire({
              icon: "error",
              text: "No se ha podido editar.",
              showConfirmButton: false,
              timer: 3000,
            });
          });
      }
    }
  };

  return (
    <>
      <Paper elevation={3}>
        <Divider />
        <div className={classes.paper}>
          <div className={classes.container}>
            <Header
              category="Parametrización evaluación docente"
              title={`${params.id ? "Editar" : "Crear"} periodo`}
            />
            <form className={classes.root} onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <TextField
                    required
                    fullWidth
                    label="Nombre"
                    name="nombre"
                    value={form.nombre}
                    variant="outlined"
                    onChange={handleInput}
                    InputProps={{
                      classes: {
                        root: classes.container__input_root,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <DateTimePicker
                    label="Fecha inicio"
                    inputVariant="outlined"
                    format="DD/MM/yyyy HH:mm"
                    fullWidth
                    value={form.fecha_inicio}
                    onChange={(value) => {
                      setForm({ ...form, fecha_inicio: value });
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <DateTimePicker
                    label="Fecha fin"
                    inputVariant="outlined"
                    fullWidth
                    format="DD/MM/yyyy HH:mm"
                    value={form.fecha_fin}
                    onChange={(value) => {
                      setForm({ ...form, fecha_fin: value });
                    }}
                  />
                </Grid>
              </Grid>
              <div className={classes.containerButton}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  type="submit"
                >
                  Guardar
                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={handleCancel}
                >
                  Cancelar
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Paper>
      <Backdrop loading={loading} />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "1em",
  },
  paper: {
    margin: theme.spacing(2, 2),
    paddingBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  container: {
    width: "80%",
  },
  containerButton: {
    marginTop: "1em",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      display: "block",
      marginTop: "1em",
      marginBottom: "1em",
    },
  },
  button: {
    margin: "0.5em",
    padding: ".5em 3em",
    borderRadius: "10px",
    backgroundColor: "#9d2d39",
    "&:hover": {
      backgroundColor: "#9d2d39",
    },
  },
  container__input_root: {
    borderRadius: "10px",
  },
}));

const mapStateToProps = (state) => {
  return {
    userId: state.user.id,
    token: state.token,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 13)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

const mapDispatchToProps = {
  setBreadcrumps,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormPeriods);
